<template>
  <div id="rmbCashBatch">
    <header-nav :content="`${$t('pages_router_021')} / ${ $t('pages_router_057')}`" />
    <a-spin :spinning="spinLoading" :tip="$t('common_text_031')" style="position: fixed; top: 20%; left: 5%">
      <div class="common-card-body">
        <div class="batch-header" @click="$router.replace('/funds/rmb')">
          <left-outlined class="ic-back" />
          {{ $t('pages_router_012') }}
        </div>
        <div class="apply-area">
          <a-form
            ref="applyRef"
            class="ant-round-form batch-apply-form"
            layout="vertical"
            :model="applyForm"
            :rules="applyRules"
            @finish="applySubmit"
            @finishFailed="submitError"
          >
            <a-form-item name="payCur" :label="$t('pages_pay_123')" style="width: 300px">
              <a-select
                v-model:value="applyForm.payCur"
                size="large"
                disabled
                class="cur-select"
              >
                <template #suffixIcon>
                  <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                </template>
                <a-select-option
                  v-for="item in cashCurList"
                  :key="item.currency"
                  :value="item.currency"
                >
                  <img :src="getCountryLogo(item.currency)" class="select-img-country">
                  <span>{{ item.currency }} - {{ item.name }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="tradeType" :label="$t('pages_pay_030')">
              <a-select v-model:value="applyForm.tradeType" :placeholder="$t('common_text_005')">
                <a-select-option
                  v-for="item in tradeTypeList"
                  :key="item.code"
                  :value="item.code"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="applyForm.tradeType" name="settlementType" :label="$t('pages_pay_036')">
              <div class="settle-list">
                <div class="settle-card active" style="width: 300px">
                  <div class="title">{{ $t('pages_pay_352') }}</div>
                  <div class="content">
                    <div>{{ $t('pages_pay_039') }}</div>
                    <div style="margin-top: 5px">{{ $t('pages_pay_353') }}</div>
                    <div v-if="feeStandard" style="margin-top: 8px">
                      <div>{{ $t('pages_pay_042') }}</div>
                      <div v-if="feeStandard.feeAmt && feeStandard.feeRate">{{ feeStandard.feeAmt + feeStandard.feeCurrency }}/{{ $t('pages_pay_295') }} + {{ $t('pages_pay_294') }}*{{ feeStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                      <div v-if="!feeStandard.feeAmt && feeStandard.feeRate">{{ $t('pages_pay_294') }}*{{ feeStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                      <div v-else>{{ feeStandard.feeAmt + feeStandard.feeCurrency }}/{{ $t('pages_pay_295') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item v-if="applyForm.tradeType" name="payPath" :label="$t('pages_pay_073')">
              <upload-auto
                v-model:file-content="applyForm.payPath"
                accept=".xls,.xlsx"
                :is-down="true"
                :notes="$t('pages_pay_074')"
                :explain="$t('pages_pay_075')"
                :max-count="1"
                @down="downPayTemplate"
              />
            </a-form-item>
            <div v-if="fillMaterial" class="flex-align">
              <span>{{ $t('pages_pay_248') }}</span>
              <a-button
                style="margin-left: auto"
                type="primary"
                size="small"
                danger
                @click="$router.push({ path: '/funds/payee_manage', query: { key: 'inbound' }})"
              >{{ $t('pages_pay_249') }}</a-button>
            </div>
            <a-form-item style="text-align: right">
              <a-button
                :loading="loading"
                html-type="submit"
                type="primary"
                shape="round"
                style="min-width: 138px; margin-top: 24px"
              >{{ $t('common_text_002') }}</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import UploadAuto from '@/components/upload-auto'
import { reactive, toRefs, watch, createVNode, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import { getCountryLogo, getAttrStatusList } from '@/util'
import { _pay } from '@/api'
import i18n from '@/locale'

export default {
  name: 'RmbCashBatch',
  components: {
    'header-nav': HeaderNav,
    'upload-auto': UploadAuto
  },
  setup () {
    const router = useRouter()
    const route = useRoute()

    const state = reactive({
      loading: false,
      spinLoading: false,
      fillMaterial: false,
      feeStandard: null,
      cashCurList: [{ currency: 'CNY', name: '人民币' }],
      applyForm: {
        accountNo: route.query.accountNo,
        payCur: 'CNY',
        receiveCur: 'CNY',
        tradeType: null,
        settlementType: '20',
        payPath: null,
        batchNo: null
      },
      applyRules: {
        payCur: [{ required: true }],
        tradeType: [{ required: true }],
        settlementType: [{ required: true }],
        payPath: [{ required: true, message: i18n.global.t('pages_pay_070') }]
      }
    })

    state.tradeTypeList = computed(() => {
      return getAttrStatusList('tradeCode')
    })

    watch(() => state.applyForm.tradeType,
      () => getReceiveStandard()
    )

    watch(() => state.applyForm.payPath,
      async (value) => {
        if (value) {
          state.spinLoading = true
          const { tradeType, settlementType, payCur, receiveCur, accountNo } = state.applyForm
          const res = await _pay.getBatchUploadAmount({
            accountNo,
            settlementType,
            bizType: tradeType,
            remitCurrency: payCur,
            arrivalCurrency: receiveCur,
            path: value,
            rate: 1,
            arithmetic: 'DIV'
          })
          state.spinLoading = false
          if (res.data.succ) {
            const { data } = res.data
            if (data.failFileName) {
              Modal.confirm({
                title: i18n.global.t('pages_pay_061'),
                content: i18n.global.t('pages_pay_062'),
                icon: createVNode(CloseCircleOutlined, { style: { color: '#ff4d4f' }}),
                okText: i18n.global.t('common_text_019'),
                onOk: () => {
                  window.open(data.failFileName)
                }
              })
            } else if (data.checkResult) {
              message.error(data.checkResult)
              state.fillMaterial = true
            } else {
              state.applyForm.batchNo = data.batchNo
              message.success(i18n.global.t('common_text_018'))
            }
          } else {
            state.applyForm.batchNo = null
            message.error(res.data.resMsg)
          }
        }
      }
    )

    const getReceiveStandard = async () => {
      state.feeStandard = null
      const res = await _pay.getFeeReceiveConfig({ tradeType: state.applyForm.tradeType })
      if (res.data.succ && res.data.data) {
        for (const item of res.data.data) {
          if (item.settlementType === '00') {
            state.feeStandard = item
            break
          }
        }
      }
    }

    const downPayTemplate = async (callback = () => {}) => {
      await _pay.downUploadTemplate({ remitCurrency: 'CNY', arrivalCurrency: 'CNY' })
      callback()
    }

    const submitError = () => {
      message.error(i18n.global.t('common_text_030'))
    }

    const applySubmit = async () => {
      state.loading = true
      const data = Object.assign({}, state.applyForm, {
        rate: 1,
        arithmetic: 'DIV'
      })
      _pay.batchWithdrawApply(data)
        .then(res => {
          state.loading = false
          if (res.data.succ) router.push({ path: '/funds/apply_success', query: { key: 'inbound' }})
          else message.error(res.data.resMsg)
        }).catch(err => console.log(err))
    }

    return {
      getCountryLogo,
      submitError,
      applySubmit,
      downPayTemplate,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/apply-batch.less';
</style>

